<template>
  <div
    style="
      max-width: 760px;
      height: 600px;
      overflow: hidden;
      position: relative;
    "
  >
    <iframe
      style="
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        box-sizing: border-box;
      "
      src="https://yandex.ru/maps-reviews-widget/231292134726?comments"
    ></iframe>
    <a
      href="https://yandex.ru/maps/org/bangbang/231292134726/"
      target="_blank"
      style="
        box-sizing: border-box;
        text-decoration: none;
        color: #b3b3b3;
        font-size: 10px;
        font-family: YS Text, sans-serif;
        padding: 0 20px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
        left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-height: 14px;
        white-space: nowrap;"
      >BangBang на карте Саратова — Яндекс Карты</a
    >
  </div>
</template>

<script>
export default {
  name: "YandexReviews",
};
</script>

<style scoped></style>
