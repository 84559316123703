import { createApp } from "vue";
import App from "./App.vue";

import GlobalPlugin from "@/plugins/globalPlugins";
import "element-plus/theme-chalk/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from "./routes";

import VueAgile from "vue-agile";
import { createMetaManager } from "vue-meta";

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(GlobalPlugin);
appInstance.use(VueAgile);
appInstance.use(createMetaManager());
appInstance.mount("#app");
