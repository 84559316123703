import IndexPage from "@/pages/IndexPage";
import { createRouter, createWebHistory } from "vue-router";
import ServicePage from "@/pages/ServicePage";
import NotFoundPage from "@/pages/NotFoundPage";
import ServicesPage from "@/pages/ServicesPage";
import ContactsPage from "@/pages/ContactsPage";

const routes = [
  {
    path: "/",
    name: "IndexPage",
    components: { default: IndexPage },
  },
  {
    path: "/services",
    name: "Services",
    components: { default: ServicesPage },
  },
  {
    path: "/services/:id",
    name: "Service",
    components: { default: ServicePage },
  },
  {
    path: "/contacts",
    name: "Contacts",
    components: { default: ContactsPage },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    components: { default: NotFoundPage },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
