<template>
  <div class="min-vh-100 bg-primary bg-primary-img py-8">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 class="text-center fs-3 text-dark">Контактная информация</h1>

          <div class="my-4 text-center">
            <ContactsInfoBtns />
          </div>

          <div class="text-dark">
            <div class="row">
              <div class="col-6 font-weight-bold my-4 my-md-0 d-flex d-flex-column">
                <span class="my-auto">Контактный номер телефона</span>
              </div>
              <div class="col-6 d-flex d-flex-column">
                <a class="text-dark my-auto" href="tel:+79061551561">+79061551561</a>
              </div>
            </div>
            <hr class="light horizontal" />
            <div class="row">
              <div class="col-6 font-weight-bold">Адрес</div>
              <div class="col-6">
                <a class="text-dark" href="https://yandex.ru/maps/-/CCU7fVgy~B" target="_blank">Саратов, улица имени Н.Г. Чернышевского, 94к7</a>
              </div>
            </div>
            <hr class="light horizontal" />
<!--            <div class="row">-->
<!--              <div class="col-6 font-weight-bold">ИНН</div>-->
<!--              <div class="col-6"></div>-->
<!--            </div>-->
<!--            <hr class="light horizontal" />-->
<!--            <div class="row">-->
<!--              <div class="col-6 font-weight-bold">ОГРН</div>-->
<!--              <div class="col-6"></div>-->
<!--            </div>-->
<!--            <hr class="light horizontal" />-->
          </div>
        </div>
        <div class="col-md-6">
          <YandexMap />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMetaWithPage } from "@/models/metadata";
import YandexMap from "@/components/YandexMap";
import ContactsInfoBtns from "@/components/ContactsInfoBtns";

export default {
  name: "ContactsPage",
  components: { ContactsInfoBtns, YandexMap },
  setup() {
    useMetaWithPage("contacts");
  },
};
</script>

<style scoped></style>
