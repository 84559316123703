<template>
  <iframe
    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab9223a4f09008abfce29caada32842a482d32cad17aa5a22df83bea85d9e681d&amp;source=constructor"
    width="100%"
    height="600"
    frameborder="0"
    class="border-radius-lg"
  ></iframe>
</template>

<script>
export default {
  name: "YandexMap",
};
</script>

<style scoped></style>
