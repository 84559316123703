<template>
  <a class="btn btn-success btn-lg mt-3 me-3 rounded-circle avatar avatar-lg"
    href="tel:+79061551561"
  >
    <i class="fas fa-phone"></i>
  </a>
  <a
    class="btn btn-vk btn-lg mt-3 me-3 rounded-circle avatar avatar-lg"
    href="https://vk.me/bangbangsar"
    target="_blank"
  >
    <i class="fab fa-vk"></i>
  </a>
  <a
    class="btn btn-warning btn-lg mt-3 me-3 rounded-circle avatar avatar-lg"
    href="https://ok.ru/group/70000001632376"
    target="_blank"
  >
    <i class="fab fa-odnoklassniki"></i>
  </a>
  <a
    class="btn btn-twitter btn-lg mt-3 me-3 rounded-circle avatar avatar-lg"
    href="https://t.me/bangbangsar"
    target="_blank"
  >
    <i class="fab fa-telegram-plane"></i>
  </a>
  <a
    class="btn btn-success btn-lg mt-3 me-3 rounded-circle avatar avatar-lg"
    href="mailto:bangbangsar@mail.ru"
  >
    <i class="fas fa-at"></i>
  </a>
</template>

<script>
export default {
  name: "ContactsInfoBtns",
};
</script>

<style scoped>

</style>
