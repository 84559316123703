<template>
  <a :href="'/services/' + service.page" class="card bg-primary h-100">
    <div class="card-body">
      <img :src="service.image" class="img-fluid border-radius-lg img-cover height-200" :alt="service.title"/>
      <div class="d-none d-md-block text-dark text-justify text-sm" v-if="isDescription">
        {{ service.promo_description }}
      </div>
      <div class="mt-4 text-dark text-center">
        <span class="del-price text-secondary">{{ service.price }} ₽</span>

        <div>
          <span class="text-dark ms-2 font-weight-bold text-lg">
            {{ Math.floor(service.price * (1 - service.discount / 100)) }} ₽
          </span>
          <span class="text-xs bg-gradient-danger border-radius-lg px-2">-{{ service.discount }}%</span>
        </div>
      </div>

      <h4 class="text-center bg-transparent text-dark mt-3 fs-6">
        {{ service.title }}
      </h4>
    </div>
  </a>
</template>

<script>
import { useMetaWithPage } from "@/models/metadata";

export default {
  name: "SingleService",
  props: {
    service: Object,
    isDescription: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    useMetaWithPage("services");
  },
};
</script>

<style scoped></style>
