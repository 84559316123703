<template>
  <div class="bg-primary">
    <div class="min-vh-100 bg-primary bg-primary-img position-relative">
      <div class="container pt-8" v-if="service">
        <div class="row">
          <div class="col-md-7 px-0 px-md-6">
            <VueAgile
              class="main_agile"
              ref="main_agile"
              :options="agile_options"
              :as-nav-for="asNavFor1"
              :key="service.slides.length"
            >
              <div
                class="slide"
                v-for="(slide, index) in service.slides"
                :key="index"
                :class="`slide--${index}`"
              >
                <img :src="slide" :alt="slide" class="border-radius-lg"/>
              </div>
            </VueAgile>
            <VueAgile class="thumbnails mt-2" ref="thumbnails" :options="thumbnails_options" :as-nav-for="asNavFor2"
                      :key="service.slides.length">
              <div class="slide slide--thumbniail" v-for="(slide, index) in service.slides" :key="index"
                   :class="`slide--${index}`"
                   @click="$refs.thumbnails.goTo(index)">
                <img :src="slide" class="border-radius-lg" :alt="slide"/>
              </div>
              <template v-slot:prevButton>
                <i class="fas fa-chevron-left text-dark"></i>
              </template>
              <template v-slot:nextButton>
                <i class="fas fa-chevron-right text-dark"></i>
              </template>
            </VueAgile>
          </div>
          <div class="col-md-5">
            <h1 class="text-center text-dark">{{ service.title }}</h1>
            <div class="mt-3 text-center fs-3">
              <span class="del-price text-secondary">{{ service.price }} ₽</span>
              <span class="text-dark ms-2 font-weight-bold fs-2">{{
                  Math.floor(service.price * (1 - service.discount / 100))
                }} ₽ </span>
              <span class="text-xs bg-gradient-danger border-radius-lg px-2">-{{ service.discount }}%</span>
            </div>
            <div class="text-justify text-dark" v-html="service.description">
            </div>

            <div class="mt-5 text-center">
              <a href="tel:+79061551561" class="btn btn-dark text-primary btn-lg">Заказать игру</a>
            </div>
          </div>
        </div>

        <h3 class="text-dark mt-7"> Другие наши услуги</h3>
        <div class="row pb-7">
          <div class="col-md-3 my-2" v-for="(service, index) in services.filter(s => parseInt(s.id) !== parseInt(service.id))" v-bind:key="index">
            <SingleService :service="service"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import {
  SITE_SERVICES,
  get_service_from_page,
  get_service_by_id,
} from "@/models/services";
import { useMetaWithPage } from "@/models/metadata";
import SingleService from "@/components/SingleService";

export default {
  name: "ServicePage",
  components: {
    SingleService,
    VueAgile,
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      agile_options: {
        dots: false,
        fade: true,
        navButtons: false,
      },
      thumbnails_options: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
            },
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
      service: null,
      services: [],
    };
  },
  methods: {},
  beforeMount() {
    this.services = SITE_SERVICES;
    this.service = get_service_from_page(this.$route.params.id);
    if (!this.service) {
      const s = get_service_by_id(this.$route.params.id);
      if (s) {
        window.location.href = "/services/" + s.page;
        return;
      } else {
        window.location.href = "/404";
        return;
      }
    }
    const page = `service-${this.service.id}`;
    useMetaWithPage(page);
  },
  mounted() {
    this.service = get_service_from_page(this.$route.params.id);

    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main_agile);
  },
  beforeRouteUpdate(to) {
    this.service = get_service_from_page(to.params.id);
  },
};
</script>

<style scoped></style>
