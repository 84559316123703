import { useMeta } from "vue-meta";

const get_current_metadata = function (page) {
  for (let meta in SITE_METADATA) {
    if (SITE_METADATA[meta].page === page) {
      return SITE_METADATA[meta];
    }
  }
  return null;
};

const useMetaWithPage = function (page) {
  const meta = get_current_metadata(page);
  if (meta) {
    useMeta({
      title: meta.title,
      description: meta.description,
      og: {
        title: meta.title,
        description: meta.description,
        image: [],
      },
    });
  }
};

const SITE_METADATA = [
  {
    page: "index",
    title: `Лазертаг в Саратове для детей и взрослых: цена игры на арене`,
    description: `Двухэтажная крытая и тёплая лазертаг-арена в центре Саратова. Рядом транспортная развязка. Доступные цены. Комнаты отдыха. Выездные игры. Получите море эмоций для всей семьи.`,
  },
  {
    page: "services",
    title: `Лазертаг в Саратове для детей и взрослых`,
    description: `Двухэтажная крытая и тёплая лазертаг-арена в центре Саратова. Рядом транспортная развязка. Доступные цены. Комнаты отдыха. Выездные игры. Получите море эмоций для всей семьи.`,
  },
  {
    page: "contacts",
    title: `Лазертаг-клуб BangBang: контакты, телефон и адрес в Саратове`,
    description: `Контактные данные клуба BangBang в Саратове. Удобное расположение в центре города в районе Набережной Космонавтов. Крытая двухэтажная арена. Отопление. Комнаты отдыха. Опытные инструкторы.`,
  },
  {
    page: "service-1",
    title: `Лазертаг для семьи, активный отдых в центре Саратова`,
    description: `Проведите семейный праздник в двухэтажной крытой и тёплой лазертаг-арене в Саратове. Рядом транспортная развязка. Доступные цены. Комнаты отдыха.`,
  },
  {
    page: "service-2",
    title: `Лазертаг для детей в Саратове: отметить детский День рождения в BangBang`,
    description: `Лёгкие тагеры. Безопасно! Тёплая закрытая арена. Опытный инструктор. Комнаты отдыха. Море положительных эмоций гарантировано!`,
  },
  {
    page: "service-3",
    title: `Выездной лазертаг для взрослых и детей в Саратове и области`,
    description: `Проводим выездные игры на вашей территории в Саратове и Саратовской области. Опытные инструкторы. Безопасно. Недорого.`,
  },
  {
    page: "service-4",
    title: `Лазертаг тренировка с клубной командой "Анаконда" цена и даты`,
    description: `Подходит для одиночек и малых групп. Сборная тренировка проходит 2 часа в интенсивном формате с PRO лазертаг-игроками на арене клуба. Повышение опыта. Безопасно. Недорого.`,
  },
  {
    page: "service-5",
    title: `Лазертаг тренировка своей командой в Саратове на клубном оборудовании`,
    description: `Есть своя команда, но негде потренироваться? Вы можете арендовать площадку с оборудованием у нас! От 8 человек. Закрытое тёплое помещение. Двухэтажная арена. Парковка. 5 минут от Ильинской площади.`,
  },
];

export { SITE_METADATA, get_current_metadata, useMetaWithPage };
