<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | BangBang` : `BangBang`
    }}</template>
    <template v-slot:og(title)="{ content, og }">
      {{ content }} - {{ og.description }}
    </template>
  </metainfo>
  <div>
    <div class="main-content">
      <MainNavigation/>
      <router-view></router-view>
      <MainFooter />
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import MainFooter from "@/components/MainFooter";
import { useActiveMeta, useMeta } from "vue-meta";

export default {
  name: "App",
  components: { MainFooter, MainNavigation },
  setup() {
    useMeta({
      title: "",
      description: "",
      og: {
        title: "",
        description: "",
        image: [],
      },
      htmlAttrs: { lang: "ru", amp: true },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
};
</script>

<style></style>
