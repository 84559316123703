<template>
  <div class="min-vh-100 bg-primary-img bg-primary">
    <div class="container py-8">
      <h1 class="text-dark text-center">Наши услуги</h1>
      <div class="row">
        <div class="col-md-4 my-4" v-for="(service, index) in services" v-bind:key="index">
          <SingleService :service="service" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SITE_SERVICES } from "@/models/services";
import SingleService from "@/components/SingleService";

export default {
  name: "ServicesPage",
  components: { SingleService },
  data() {
    return {
      services: [],
    };
  },
  beforeMount() {
    this.services = SITE_SERVICES;
  },
};
</script>

<style scoped></style>
