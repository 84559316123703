<template>
  <div class="min-vh-25 bg-dark text-primary py-6">
    <div class="text-center">
      <div>
        <h3 class="text-primary">Свяжись с нами где удобнее</h3>
        <div class="">
          <a href="/contacts" class="btn btn-primary btn-lg mt-3 me-3 text-dark">
            <i class="fas fa-info me-3"></i> Подробная информация
          </a>
        </div>
        <ContactsInfoBtns />
      </div>
      <div class="container">
        <div class="text-start py-4">

        </div>
      </div>

      <div>
        <!--          <span class="fs-4">Или звони <a href="tel:+79061551561" class="text-primary">+7 (906) 155-15-61</a> Развлечём 😉</span>-->
      </div>

      <div class="mt-4 pb-4 container">
        <div class="row">
          <div class="col-md-6">
            <YandexReviews />
          </div>

          <div class="col-md-6">
            <YandexMap />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YandexReviews from "@/components/YandexReviews";
import YandexMap from "@/components/YandexMap";
import ContactsInfoBtns from "@/components/ContactsInfoBtns";
export default {
  name: "MainFooter",
  components: { ContactsInfoBtns, YandexMap, YandexReviews },
};
</script>

<style scoped></style>
